import React, { useState } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import policy from "../assets/policy.pdf";

function Policy() {

  return (
    <div id="page-top" className="main min-vh-100 d-flex flex-column">
      <Navbar />

      <div><iframe
            style={{ width: "100%", height: "100vh" }}
            src={policy}
            type='application/pdf'
            title='title'
          /></div>

      <Footer />
    </div>
  );
}

export default Policy;
