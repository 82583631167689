import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircle,
    faCameraRotate,
    faLaptop,
    faRobot
} from "@fortawesome/free-solid-svg-icons"

const Services = () => {
  return (
    <section id="services">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2 className="section-heading">Services</h2>
                    <h3 className="section-subheading text-muted">We give what you need at high rate.</h3>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <FontAwesomeIcon className='fa fa-circle fa-stack-2x' icon={faCircle}/>
                        <FontAwesomeIcon className="fa fa-atom-simple fa-stack-1x fa-inverse" icon={faRobot}/>
                    </span>
                    <h4 className="service-heading">Electronics Systems</h4>
                    <p className="text-muted">Home automation now days is something, 
                    With us gonna make your home feels like a new world where everything is monitored.
                    </p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <FontAwesomeIcon className='fa fa-circle fa-stack-2x' icon={faCircle}/>
                        <FontAwesomeIcon className="fa fa-laptop fa-stack-1x fa-inverse" icon={faLaptop}/>
                    </span>
                    <h4 className="service-heading">SoftWare Development</h4>
                    <p className="text-muted">Make stunning Website and Application with us by your own budget and at high rate and quality without to forget you will get free Help anytime!</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <FontAwesomeIcon className='fa fa-circle fa-stack-2x' icon={faCircle}/>
                        <FontAwesomeIcon className="fa fa-lock fa-stack-1x fa-inverse" icon={faCameraRotate}/>
                    </span>
                    <h4 className="service-heading">Multimedia Activities</h4>
                    <p className="text-muted">Let us give you best design of your desire Photography and Cinematography at low cost at much best quality.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services