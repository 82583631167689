
const Header = () => {
  return (
    <div className="header">
      <div className="intro-text">
        <div className="intro-lead-in">Welcome To Our Space!</div>
        <div className="intro-heading">It is Nice To Meet You</div>
        <a href="#services" className="page-scroll text-dark btn btn-xl">
          Tell Us More
        </a>
      </div>
    </div>
  );
};

export default Header;
