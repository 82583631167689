import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFacebook,
    faTwitter,
    faLinkedin
  } from "@fortawesome/free-brands-svg-icons";

const Team = () => {
  return (
    <section id="team" className="bg-light-gray">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2 className="section-heading">Our Amazing Team</h2>
                    <h3 className="section-subheading">Meet My top leading team.</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div className="team-member">
                        <img src={require("../assets/team/ceo.jpg")} className="img-responsive img-circle" alt=""/>
                        <h4>Anna Samila</h4>
                        <p className="p-0 m-0">C.E.O</p>
                        <ul className="d-flex text-center p-0 social-buttons">
                            <div className='mx-auto d-flex'>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-twitter" icon={faTwitter}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-facebook" icon={faFacebook}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-linkedin" icon={faLinkedin}/>
                                </a>
                            </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="team-member">
                        <img src={require("../assets/team/12.png")} className="rotate90 img-responsive img-circle" alt=""/>
                        <h4>Hasani Mkindi</h4>
                        <p className="p-0 m-0">The FOUNDER</p>
                        <p className="text-muted">Lead Software Development</p>
                        <ul className="d-flex text-center p-0 social-buttons">
                            <div className='mx-auto d-flex'>
                            <li>
                                <a href="https://twitter.com/afrodemoz" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-twitter" icon={faTwitter}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-facebook" icon={faFacebook}/>
                                </a>
                            </li>
                            <li>
                                <a href="https://tz.linkedin.com/in/demo-mkindi-6815411b3" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-linkedin" icon={faLinkedin}/>
                                </a>
                            </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="team-member">
                        <img src={require("../assets/team/tm.jpg")} className="img-responsive img-circle" alt=""/>
                        <h4>Adnani Jacob</h4>
                        <p className="">Technical Manager</p>
                        <ul className="d-flex text-center p-0 social-buttons">
                            <div className='mx-auto d-flex'>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-twitter" icon={faTwitter}/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=61551947738772" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-facebook" icon={faFacebook}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-linkedin" icon={faLinkedin}/>
                                </a>
                            </li>
                            </div>
                        </ul>
                    </div>
                </div>
                {/* <div className="col-sm-4">
                    <div className="team-member">
                        <img src={require("../assets/team/gm.jpg")} className="img-responsive img-circle" alt=""/>
                        <h4>Godian Malagwa</h4>
                        <p className="">Lead Engineering</p>
                        <ul className="d-flex text-center p-0 social-buttons">
                            <div className='mx-auto d-flex'>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-twitter" icon={faTwitter}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-facebook" icon={faFacebook}/>
                                </a>
                            </li>
                            <li>
                                <a href="#" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon className="fa fa-linkedin" icon={faLinkedin}/>
                                </a>
                            </li>
                            </div>
                        </ul>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-lg-8 mx-auto col-lg-offset-2 text-center">
                    <p className="large">Our team cooperate side by side to deriver brutal package per client needs We care so much that we will not dare to miss a chance.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Team
