import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <span className="copyright">Copyright &copy; AfroDemoz 2016</span>
          </div>
          <div className="col-md-4">
            <ul className="d-flex text-center p-0 social-buttons">
              <div className="mx-auto d-flex">
                <li>
                  <a href="https://twitter.com/afrodemoz" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon
                      className="fa fa-twitter"
                      icon={faTwitter}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/c/AfroDemoztz" rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon
                      className="fa fa-youtube"
                      icon={faYoutube}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://tz.linkedin.com/in/demo-mkindi-6815411b3" rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      className="fa fa-linkedin"
                      icon={faLinkedin}
                    />
                  </a>
                </li>
              </div>
            </ul>
          </div>
          <div className="col-md-4">
            <span>
            <a href="#">Privacy Policy</a> || <a href="#">Terms of Use</a>
          </span></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
