import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import fid from "../assets/portfolio/fidport.PNG"
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Portifolio = () => {
  return (
    <section id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Portfolio</h2>
            <h3 className="section-subheading text-muted">
              Best among us and so far done.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 portfolio-item">
            <a
              href="https://fareed.vercel.app/" rel="noopener noreferrer"
              target="_blank"
              className="portfolio-link"
              data-toggle="modal"
            >
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <FontAwesomeIcon icon={faPlus} className="fa fa-plus fa-3x" />
                </div>
              </div>
              <img
                src={fid}
                className="img-responsive"
                alt="fidport"
              />
            </a>
            <div className="portfolio-caption">
              <h4>Fareed Yahya Portfolio</h4>
              <p className="text-muted">Website Design & Development</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 portfolio-item">
            <a
              href="https://play.google.com/store/apps/developer?id=ADZ+Community" rel="noopener noreferrer"
              target="_blank"
              className="portfolio-link"
              data-toggle="modal"
            >
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <FontAwesomeIcon icon={faPlus} className="fa fa-plus fa-3x" />
                </div>
              </div>
              <img
                src={require("../assets/portfolio/tracker.png")}
                className="img-responsive"
                alt=""
              />
            </a>
            <div className="portfolio-caption">
              <h4>Expenses Tracker App</h4>
              <p className="text-muted">Application Development</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 portfolio-item">
            <a
              href="https://mbclandbrug.com/" rel="noopener noreferrer"
              target="_blank"
              className="portfolio-link"
              data-toggle="modal"
            >
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <FontAwesomeIcon icon={faPlus} className="fa fa-plus fa-3x" />
                </div>
              </div>
              <img
                src={require("../assets/portfolio/mbc.PNG")}
                className="img-responsive"
                alt=""
              />
            </a>
            <div className="portfolio-caption">
              <h4>MBC Landbrug Profile</h4>
              <p className="text-muted">Website Design & Development</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 portfolio-item">
            <a
              href="/#portfolio"
              className="portfolio-link"
              data-toggle="modal"
            >
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <FontAwesomeIcon icon={faPlus} className="fa fa-plus fa-3x" />
                </div>
              </div>
              <img
                src={require("../assets/portfolio/roundicons-free.jpg")}
                className="img-responsive"
                alt=""
              />
            </a>
            <div className="portfolio-caption">
              <h4>Round Icons</h4>
              <p className="text-muted">Graphic Design</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 portfolio-item">
            <a
              href="https://www.fareedyahya.tk/#projects" rel="noopener noreferrer"
              target="_blank"
              className="portfolio-link"
              data-toggle="modal"
            >
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <FontAwesomeIcon icon={faPlus} className="fa fa-plus fa-3x" />
                </div>
              </div>
              <img
                src={require("../assets/portfolio/fisi.jpg")}
                className="img-responsive"
                alt=""
              />
            </a>
            <div className="portfolio-caption">
              <h4>Tanzania National Park</h4>
              <p className="text-muted">WildLife Photography</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 portfolio-item">
            <a
              href="/#portfolio"
              className="portfolio-link"
              data-toggle="modal"
            >
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <FontAwesomeIcon icon={faPlus} className="fa fa-plus fa-3x" />
                </div>
              </div>
              <img
                src={require("../assets/portfolio/toilet.PNG")}
                className="img-responsive"
                alt=""
              />
            </a>
            <div className="portfolio-caption">
              <h4>Public Toilet System</h4>
              <p className="text-muted">Automation System</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <a href="#services" className="page-scroll text-dark btn btn-xl">
              Check Out More
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portifolio;
