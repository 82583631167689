import React from 'react'

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Portifolio from "../components/Portifolio";
import About from "../components/About";
import Team from "../components/Team";
import Clients from "../components/Clients";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Home() {
  return (
    <div id="page-top" className="main min-vh-100 d-flex flex-column">
      <Navbar/>
      <Header/>
      <Services/>
      <Portifolio/>
      <About/>
      <Team/>
      <Clients/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home