
const About = () => {
  return (
    <section id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2 className="section-heading">About</h2>
                    <h3 className="section-subheading">Our life struggle.</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <ul className="timeline">
                        <li>
                            <div className="timeline-image">
                                <img className="img-circle img-responsive" src={require("../assets/about/1.jpg")} alt=""/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>2014-2016</h4>
                                    <h5 className="subheading">Our Humble Beginnings</h5>
                                </div>
                                <div className="timeline-body">
                                    <p>It was not that easy or that hard because every start point got a crest. We struggled but 
                                    with that only one hope along with our big vision and we sure we will make it through.</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-image">
                                <img className="img-circle img-responsive" src={require("../assets/about/2.jpg")} alt=""/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>April 07 2016</h4>
                                    <h5 className="subheading">An Agency is Born</h5>
                                </div>
                                <div className="timeline-body">
                                    <p>Hope is the only girl that made us where we are but do not ever think that this girl is EasyGoing as it sound believe me even our C.E.O took a note about
                                    her and saluted about her but he made sure atleast we make through some simple thing to make us realize
                                    our success that we managed to create our first YouTube channel!</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-image">
                                <img className="img-circle img-responsive" src={require("../assets/about/3.jpg")} alt=""/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>December 2018</h4>
                                    <h5 className="subheading">Transition to Full Service</h5>
                                </div>
                                <div className="timeline-body">
                                    <p>God bless is always with us. We decided to expand our boundaries and bring many services
                                     and help others by giving them many opotunities via us as many as we could. We opened doors to other
                                     companies to work with us because we are human afterall not Angels</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-image">
                                <img className="img-circle img-responsive" src={require("../assets/about/4.jpg")} alt=""/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>July 2019</h4>
                                    <h5 className="subheading">Phase Two Expansion</h5>
                                </div>
                                <div className="timeline-body">
                                    <p>The more you join forces with other force the stronger you be come as long as no one is been
                                    used unfair that is why we agree to coperate with JAM but it did not work out as you can
                                    not make everyone happy then we cooperated with C.V which deals with photography untill today we are good
                                    and still our door is always open!</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-image text-dark">
                                <h4 className="text-dark">Be Part
                                    <br/>Of Our
                                    <br/>Life!</h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
  )
}

export default About