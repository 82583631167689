import { useState } from "react";

import policy from "../assets/policy.pdf";

const Navbar = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <>
      <nav className="flex shadow-2xl items-center flex-wrap p-3 navbar navbar-default navbar-custom navbar-fixed-top">
        <a href="/">
          <a className="navbar-brand page-scroll inline-flex items-center p-2 mr-4 ">
            <img src={require('../assets/LogoW.png')} className="fill-current text-white h-6 w-8 mr-2"/>
            <span className="kichwa text-xl text-white font-bold tracking-wide">
              AfroDemoz
            </span>
          </a>
        </a>
        <button
          className=" inline-flex p-3 rounded lg:hidden text-white ml-auto hover:text-white outline-none"
          onClick={handleClick}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        {/*Note that in this div we will use a ternary operator to decide whether or not to display the content of the div  */}
        <div
          className={`${
            active ? "" : "hidden"
          }   w-full lg:inline-flex lg:flex-grow lg:w-auto`}
        >
          <div className="nav lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
          
            <a href="/#services">
              <a className="page-scroll lg:inline-flex lg:w-auto w-full px-3 py-2 rounded font-bold items-center justify-center">
              Services
              </a>
            </a>
            <a href="/#portfolio">
              <a className="page-scroll lg:inline-flex lg:w-auto w-full px-3 py-2 rounded font-bold items-center justify-center">
              Portfolio
              </a>
            </a>
            <a href="/#about">
              <a className="page-scroll lg:inline-flex lg:w-auto w-full px-3 py-2 rounded font-bold items-center justify-center">
                About us
              </a>
            </a>
            <a href="/#team">
              <a className="page-scroll lg:inline-flex lg:w-auto w-full px-3 py-2 rounded font-bold items-center justify-center">
              Our Team
              </a>
            </a>
            <a href="/#contact">
              <a className="page-scroll lg:inline-flex lg:w-auto w-full px-3 py-2 rounded font-bold items-center justify-center">
              Contact Us
              </a>
            </a>
            <a href = {policy} target = "_blank">
              <a className="page-scroll lg:inline-flex lg:w-auto w-full px-3 py-2 rounded font-bold items-center justify-center">
              Privacy Policy
              </a>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
